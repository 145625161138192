// extracted by mini-css-extract-plugin
export const contentBannerCta = "index-module__contentBannerCta--1UY1k";
export const blockContent = "block-content";
export const headline = "headline";
export const description = "description";
export const blockMore = "block-more";
export const item = "item";
export const actionLink = "action-link";
export const innerWrapper = "inner-wrapper";
export const mediaContainer = "media-container";
export const content = "content";
export const linkBtn = "linkBtn";