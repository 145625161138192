import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import Container from '../components/container'
import ContentHeroNew from '../components/content-hero-new'
import Contact from '../components/contact'
import ContentBlockLarge from '../components/content-block-large'
import ContentBannerWithCTA from '../components/content-banner-with-cta'

import { enterpriseHero, enterpriseHeroImage, contactContainer } from './enterprise.module.scss'

const IndexPage = () => (
  <MainContainer>
    <SEO title="Basaas Workplace" />
    <Container className={enterpriseHero}>
      <ContentHeroNew
        kicker="Scalable and customizable digital work platform "
        headline="Empower your entire organization for the digital age"
        // description={
        //   <React.Fragment>
        //     <p>
        //       At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements. We also take the security of our
        //       infrastructure very seriously and are committed to providing an environment that is safe, secure, and stable.{' '}
        //     </p>
        //     <p> We are aware of our responsibility of sensitive company data. The Basaas security concept is based on international best in class standards and EU requirements. </p>
        //   </React.Fragment>
        // }
        actionLinkText="Book a Demo"
        // actionLinkIsExternal
        actionLink="/demo"
        // media={<StaticImage src="../assets/images/placeholder-landscape.png" alt="Optimize your app management" placeholder="blurred" />}
      />
    </Container>
    <Container className={enterpriseHeroImage}>
      <StaticImage src="../assets/images/enterprise-hero.png" alt="Optimize your app management" placeholder="blurred" />
    </Container>
    <Container withBackground style={{ padding: '80px 0' }} id="workplace-for-teams">
      <ContentBlockLarge
        kicker="Basaas as your future-proof digital hub"
        headline="Benefit from a flexible and extensible digital work platform"
        description="Empower all your teams for digital work with a scalable and individual Digital Hub. Basaas is based on a fully managed and future-proof technology stack which you can utilize without effort. The solution is device agnostic, fully responsive, delivering a streamlined and outstanding user experience. If you have enterprise-specific requirements, you can easily realize them with Basaas as part of an individual project."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/home-hero.png" alt="Optimize your app management" placeholder="blurred" />}
        reverse
      />

      <ContentBlockLarge
        kicker="Connect apps and data with speed and ease"
        headline="Get custom connectors for all your existing SaaS solutions"
        description="Every team should be free to use the tools and platforms they choose. Don’t let adapters and connectors limit your operations. With Basaas you can integrate and use any app or system without limitations. If you can't find your app in Basaas or you need specific connectors for existing solutions, we could develop individual connectors you require."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/enterprise-custom-connector.png" alt="Optimize your app management" placeholder="blurred" />}
      />

      <ContentBlockLarge
        kicker="Benefit from our individual enterprise support "
        headline="Accelerate your Time To Value faster with individual enterprise support"
        description="Benefit from our individual enterprise support which includes provisioning of Basaas including all operations, 24/7 incident support and an efficient 2nd level support. Get also professional support to check an implementation within the framework of a PoC (proof of concept). Through our international partner ecosystem, you can get guidance for digital transition or migration of legacy systems."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/enterprise-enterprise-support.png" alt="Optimize your app management" placeholder="blurred" />}
        reverse
      />

      <ContentBlockLarge
        kicker="Enhanced IT security and overview"
        headline="Gain more control and transparency for your IT"
        description={
          <React.Fragment>
            Enhance security and get more control and transparency for your SaaS IT through access policies (IP, Geolocation, time schedules, etc.) and alerting. Gain insights into your company’s apps
            usage. An anonymized company-wide app usage analytics gives valuable insight about known and unknown app usage. You can understand your colleagues needs and introduce solutions they
            require.
            <p>
              <Link to="/security">Learn more about data security and EU-GDPR compliance</Link>
            </p>
          </React.Fragment>
        }
        // actionLinkText="Learn more"
        // actionLink="/data-security"
        media={<StaticImage src="../assets/images/analytics.png" alt="Optimize your app management" placeholder="blurred" />}
      />
    </Container>

    <div className={`${contactContainer}`}>
      <div className="inner-wrapper">
        <div className="content">
          <div className="content-inner">
            {/* <span className="kicker">Request a demo</span> */}
            <h1 className="headline">Let’s talk how you can benefit from a Digital Hub and how you can:</h1>
            {/* <span className="subheadline">Get to know Basaas in a personal demo:</span> */}
            <ul className="demo-list" style={{ marginTop: '32px' }}>
              <li className="list-item">Optimize your digital employee experience</li>
              <li className="list-item">Improve your workforce productivity</li>
              <li className="list-item">Manage all apps your employees love</li>
              <li className="list-item">Get all integrations your teams need</li>
              <li className="list-item">Conduct a proof of concept for a secure decision</li>
            </ul>
            <span className="subheadline">We look forward to meeting with you!</span>
            <div className="avatar-container">
              <div className="avatar-item">
                {/* <img src={AvatarFrank} alt="Frank, CEO" /> */}
                <StaticImage className="avatar-img" src="../assets/images/avatar-frank.jpg" alt="Frank, CEO" placeholder="blurred" />
                <span className="avatar-name">Frank, CEO</span>
              </div>
              <div className="avatar-item">
                <StaticImage className="avatar-img" src="../assets/images/avatar-selim.jpg" alt="Selim, CTO" placeholder="blurred" />
                <span className="avatar-name">Selim, CTO</span>
              </div>
              <div className="avatar-item">
                <StaticImage className="avatar-img" src="../assets/images/avatar-daniel.jpg" alt="Daniel, CEO" placeholder="blurred" />
                <span className="avatar-name">Daniel, CPO</span>
              </div>
            </div>
            <span className="more-info">
              You have specific requirements or a request for information? Please send us an Email to <a href="mailto:enterprise@basaas.com">enterprise@basaas.com</a>
            </span>
          </div>
        </div>
        <div className="form">
          <Contact type="book-demo" />
        </div>
      </div>
    </div>

    <Container style={{ padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage
