import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { contentBannerCta } from './index.module.scss'

const ContentBannerWithCTA = props => (
  <div className={contentBannerCta}>
    <div className="block-content">
      <div className={`inner-wrapper ${props.reverse ? 'reverse' : ''}`}>
        <div className="content">
          <span className="headline">{props.headline}</span>
          <span className="description">{props.description}</span>

          {props.actionLink &&
            (props.actionLinkIsExternal ? (
              <a className="linkBtn primary" href={props.actionLink} target={props.actionLinkInNewWindow ? '_blank' : '_self'} rel={props.actionLinkInNewWindow ? 'noreferrer' : ''}>
                {props.actionLinkText}
              </a>
            ) : (
              <Link to={props.actionLink}>{props.actionLinkText}</Link>
            ))}
        </div>
        {/* <div className="media-container">{props.media || <StaticImage className="media-item" src="../assets/images/app-icons.svg" alt="Optimize your app management" placeholder="blurred" />}</div> */}
      </div>
    </div>
    <div className="block-more">
      <Link className="item" to={'/demo'}>
        <span className="headline">Book a demo</span>
        <span className="description">Get in touch and see how you can benefit from a Digital Hub within your company.</span>
        <span className="action-link">Book a demo</span>
      </Link>

      <Link className="item" to={'/pricing'}>
        <span className="headline">Find the right plan for your company</span>
        <span className="description">You can use Basaas for free in a powerful basic version. Upgrade whenever you feel like.</span>
        <span className="action-link">See pricing</span>
      </Link>

      {/* <a className="item" target="_blank" rel="noreferrer" href={'https://help.basaas.com'}>
        <span className="headline">Need support or assistance?</span>
        <span className="description">Explore our Help Center and learn everything from basics features to advanced shortcuts.</span>
        <span className="action-link" target="_blank" rel="noreferrer" href={'https://help.basaas.com'}>
          Go to Help Center
        </span>
      </a> */}
    </div>
  </div>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

ContentBannerWithCTA.defaultProps = {
  headline: 'Start today with basaas for free',
  description: 'Sign-up and start revolutionizing the way you work.',
  actionLinkIsExternal: true,
  actionLink: 'https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=footer&utm_campaign=signup&utm_content=home',
  actionLinkText: "Get started – It's free!",
  // media: <StaticImage src="../assets/images/app-icons.svg" alt="Optimize your app management" placeholder="blurred" />,
}

export default ContentBannerWithCTA
